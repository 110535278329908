<script lang="ts">
  import { screen } from '$stores';

  import Footer from '$components/Common/Footer/Footer.svelte';
  import { LandingTopNavBar } from '$components/Common/TopNavBar';
  import { RibbonLanding } from '$components/Common/Ribbon';
  import TaikoLogo from '$images/taiko-h-wht.svg';
</script>

<div
  class="flex flex-col h-full bg-landing-page-mobile lg:bg-landing-page bg-cover bg-lightgrey/[.5] bg-[center_top_2rem] lg:bg-top">
  <div class="flex flex-col relative top-0 w-full items-center justify-center">
    <LandingTopNavBar />
    <RibbonLanding />
  </div>
  <!-- Body -->
  <div class="flex w-full py-8 lg:px-10 h-full justify-center">
    <!-- <div class="absolute h-full w-full bg-landing-page bg-cover bg-center opacity-15 z-0"></div> -->
    <div
      class="flex flex-col max-w-[1440px] w-full lg:flex-row h-full items-center justify-center 2xl:px-10 lg:justify-between text-center lg:text-left gap-4 lg:items-end pb-16">
      <div class="flex flex-col">
        <!-- {#if $screen == 'desktop'}
          <div class="coming-soon">Coming Soon</div>
        {/if} -->
        <div class="body-large-semibold text-[36px]/[44px] lg:text-[100px]/[normal]">
          <div
            style="text-shadow: 3px 3px 19px rgba(0, 0, 0, 0.25);"
            class="bg-clip-text text-transparent title-gradient">
            Supercharged Liquidity Markets
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4 lg:gap-6 justify-center items-center lg:items-start">
        <div class="flex gap-4 order-1 lg:order-0">
          <a href="/market">
            <button class="primary-button">Open App</button>
          </a>
          <!-- <button
            class="primary-button bg-transparent border-2 flex gap-2 box-border h-[34px]"
          >Docs 
            <img src={DocsLogo} class="h-3" alt="Docs logo"/>
          </button> -->
        </div>
        <div class="body-medium lg:text-[20px]/[normal] order-0 lg:order-1 w-[290px] xl:w-[340px]">
          Lend, Borrow, and Earn Yields on Taiko's Leading Money Market
        </div>
        <!-- {#if $screen == 'mobile'}
          <div class="coming-soon">Coming Soon</div>
        {/if} -->
        <div class="flex gap-2 body-regular text-[12px]/[normal] lg:text-[16px]/[normal] items-center order-2">
          Powered By <div class="h-[16px]"><img class="h-4" src={TaikoLogo} alt="logo" /></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="flex flex-col relative w-full items-center justify-center">
    <Footer />
  </div>
</div>

<style>
  .title-gradient {
    background-image: linear-gradient(93deg, #fff -1.19%, #ffd9f1 34.93%, #c8fff3 67.96%, #ffabdf 102.01%);
  }
</style>
