<script lang="ts">
  import Logo from '$images/hana-logo-pink.png';
  import { screen } from '$stores';
  import { socials } from '$configs/socials';
</script>

<div class="flex justify-between items-center max-w-[1440px] px-4 py-4 lg:px-10 w-full lg:py-4">
  {#if $screen == 'mobile'}
    <div class="flex justify-between w-full items-center">
      <div class="h-[28px] w-[110px]">
        <img src={Logo} alt="logo" color="white" />
      </div>
      <a href="/market" class="primary-button">Open App</a>
    </div>
  {/if}
  {#if $screen == 'desktop'}
    <div class="h-[28px] w-[110px]">
      <img src={Logo} alt="logo" color="white" />
    </div>

    <div class="flex gap-6 items-center">
      <!-- logos -->
      <div class="flex gap-[30px] h-fit">
        {#each socials as social}
          <a href={social.link} target="_blank">
            <div class="social-button">
              <img src={social.icon} alt={social.label} />
            </div>
          </a>
        {/each}
      </div>
      <a href="/market" class="primary-button">Open App</a>
    </div>
  {/if}
</div>
