<script lang="ts">
  import { screen } from '$stores';
</script>

{#if $screen == 'mobile'}
  <div class="w-full inline-flex overflow-hidden flex-nowrap border-y border-[#6AFFDE]">
    <div class="flex items-center gap-4 animate-[infinite-scroll_120s_linear_infinite]">
      {#each Array(4) as _}
        <div class="body-small-semibold lg:text-sm text-[#6AFFDE] text-center py-[7px] whitespace-nowrap">
          Hana Finance is Live on Taiko Mainnet! Try out the leading native money market on Taiko.
        </div>
        <div class="body-small-semibold lg:text-sm text-[#6AFFDE] py-[7px]">•</div>
      {/each}
    </div>
  </div>
{/if}
{#if $screen == 'desktop'}
  <div class="flex w-full overflow-hidden border-y border-[#6AFFDE] items-center justify-center">
    <div class="body-small-semibold lg:text-sm text-[#6AFFDE] text-center py-[7px] whitespace-nowrap">
      Hana Finance is Live on Taiko Mainnet! Try out the leading native money market on Taiko.
    </div>
  </div>
{/if}
